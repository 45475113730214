
#container {
  touch-action: pinch-zoom;
}

#mainPic {
  max-height: 100vh;
  width: auto;
  position:absolute;
  top:0; left:0; right:0; bottom:0;
  margin:auto;
  z-index: -1;
  touch-action: pinch-zoom;
}
#brush {
  position: fixed;
  left: 8%;
  top: 20%;
  touch-action: none;
}

#mobileBrush {
  position: fixed;
  left: 2%;
  touch-action: none;
}

button {
  color: black !important;
  text-transform: uppercase;
  background: #ffffff;
  padding: 5px;
  border: 1px solid gray !important;
  border-radius: 6px;
  display: inline-block;
  box-shadow: 1px 2px 3px gray;
}

button:focus {
  outline: none
}

button:active {
  transform: translate(1px, 2px);
  box-shadow: none
}

#draw {
  touch-action: pinch-zoom;
}

.clearButton {
  margin: 50px 0px 0px 0px;
}

ul {
  list-style-type: none;
}